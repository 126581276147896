






































import moment from 'moment';
import {Component, Prop, VModel, Vue} from 'vue-property-decorator';
@Component({
  components: {
  },
})
export default class TimeTrackingHeaderComponent extends Vue {

  @VModel({required: true})
  private pickerDate!: string;

  @Prop({required: true})
  private mode!: 'day' | 'week' | 'custom';

  private weekDisplay() {
    const startOfWeek = moment(this.pickerDate)
        .startOf('week').format(this.$t('GENERAL.DATE_FORMATS.DATE').toString());
    const endOfWeek = moment(this.pickerDate)
        .endOf('week').format(this.$t('GENERAL.DATE_FORMATS.DATE').toString());
    return startOfWeek + ' - ' + endOfWeek;
  }

  private addPeriod(amount: number) {
    const period = this.mode === 'day' ? 'day' : 'week';
    this.pickerDate = moment(this.pickerDate).add(amount, period).format(this.$t('GENERAL.DATE_FORMATS.PICKER_DATE').toString());
  }
  private get weekNumber() {
    return moment(this.pickerDate).week();
  }

  private goToToday() {
    this.pickerDate = moment().format(this.$t('GENERAL.DATE_FORMATS.PICKER_DATE').toString());
  }
}
